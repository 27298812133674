import log from '../helpers/log';
import waitFor from "../helpers/waitfor";
export const GROWTHBOOK_TYPES = {
    VIDEO: 'video',
    DISPLAY: 'display'
}
const getRegisteredExperiments = (): GrowthbookKeyValue[]  => window?.growthbooks || [];

const matchGrowthbookExperiment = (value: string, name: string) => {
    const registredExperiments = getRegisteredExperiments();
    const growthbookKey = `${name}:${value}`;
    log(`Growthbook active test:: resolve with key ${growthbookKey}`, registredExperiments);
    return registredExperiments.find( item => item?.experimentId === growthbookKey);        
}

/**
 * Checks for existence of growthbook tests by type so that we don't have to waiting for
 * the callback to happen in vain.
 * Format {: ' NsXUb1zXTCaUJLBaHPmdZQ:1', type: 'video', version: 'a'}
 * @param type {string} 'video' or 'display'
 * @return {boolean}
 */
export const hasRegisteredTests =  (type: string) => {
    const exp = getRegisteredExperiments().some( itm => itm.type === type);
    log(`Growthbook hasRegisteredTests(${type})`, exp);
    return exp;
}

interface CallableExperiment { 
    [key: string]: (version: string) => void 
}

let experimentHandlers: CallableExperiment = {};

const activeExperiments: { [type: string]: string } = {};

let isGbEventListenerAdded = false;


export const handleExperiment = (type: string, version: string) => {
    const target = experimentHandlers[type];
    if (typeof target === 'function') {
        target(version);
    } else {
        log(`Growthbook no callback handler found for type ${type} with version ${version}`);
    }
}

export const hasActiveExperiment = (type: string) => {
    const active = activeExperiments[type] ?? undefined;
    active && log(`Growthbook hasActiveExperiment(${type})`, active);
    return active;
}


/**
 * Wait for an active experiment of the requested type.
 * @param type {string}
 * @return {function}
 */
const getGrowthbookGroup = (type: string) => async () =>
    hasRegisteredTests(type) ?
    waitFor( () =>hasActiveExperiment(type), { timeout: 500, errorMessage: `No Active experiment match for ${type}. Please remove registred tests to avoid delay.`}) :
    Promise.resolve(log(`Growthbook, no available experiments for ${type}`))
    
/**
 * Retrieve the video experiment group
 * @return {promise}
 */
export const getGrowthbookGroupVideo = getGrowthbookGroup(GROWTHBOOK_TYPES.VIDEO)

/**
 * Retrieve the display experiment group
 * @return {promise}
 */
const getGrowthbookGroupDisplay = getGrowthbookGroup(GROWTHBOOK_TYPES.DISPLAY)

/**
 * Target ab-version on display requests
 * @param version {string}
 */
const targetOnDisplayRequests = (version: string) => window.googletag.cmd.push(() => window.googletag.pubads().setTargeting(`abgroup`, version));

/**
 * Wait for the display experiment version to be set 
 * @return {promise}
 */
const waitForGrowthbookTestForDisplay = async () => {
    try {
        const gbVersion = await getGrowthbookGroupDisplay();
        log('gbVersion', gbVersion)
        gbVersion && targetOnDisplayRequests(gbVersion);
    } catch (error) {
        console.warn('Growthbook got no event that could be matched', error);            
    }    
}

/**
 * Handle GrowthBook Experiment event
 * @param value {string} Experiment Version
 * @param name {string} Experiment name
 */
const onGrowthbookEvent = (value: string, name: string) => {

    const growthbooks = getRegisteredExperiments();

    if (growthbooks.length === 0) {
        console.warn(`No registred Experiments from Ads MS, but got Growthbook event with ${name} : ${value}`);
    } else {        
        const growthbookExperiments = matchGrowthbookExperiment(value, name);
    
        let type, version, experimentId;
        if (growthbookExperiments) {
            type = growthbookExperiments.type; 
            version = growthbookExperiments.version;
            experimentId = growthbookExperiments.experimentId;
        }
    
        if (!experimentId) {
            console.warn(`Growthbook no match for ${name} : ${value}`, growthbooks);
        } else {
            activeExperiments[type] && log(`Growthbook: received experiment group multiple times for ${type}`);
            activeExperiments[type] = version;    

            handleExperiment(type, version)
        }
    }
}

/**
 * Catch Growthbook Experiment custom event
 */
const catchGrowthbookEvents = () => {
    if (!isGbEventListenerAdded) {

        // Handle already saved Growthbook custom events on window.gbVersionAds
        if (window.gbVersionAds && Array.isArray(window.gbVersionAds)) {

            for (let gbTrackingKeys of window.gbVersionAds) {

                const [versionKey, experimentKey] = gbTrackingKeys;
                onGrowthbookEvent(versionKey, experimentKey);
            }
        }
        // Handle coming Growthbook custom events
        window.addEventListener('gb:tracking-version-callback', (e: Event) => {
            const [versionKey, experimentKey] = (e as CustomEvent)?.detail || [];

            log('GrowthBook EventListener gb:tracking-version-callback', versionKey, experimentKey);
            onGrowthbookEvent(versionKey, experimentKey);
            isGbEventListenerAdded = true;
        });
    }
}

/**
 * Enable Growthbook Client AB testing.
 *  - Add a callback function for the group type
 * @param callbacksByType {object} Callbacks for each type, optional
 */
export const enableGrowthbook = async (callbacksByType = {}) => {

    experimentHandlers = { ...experimentHandlers, ...callbacksByType }

    catchGrowthbookEvents();
    if(hasRegisteredTests(GROWTHBOOK_TYPES.DISPLAY)){

        await waitForGrowthbookTestForDisplay();

    }
}

