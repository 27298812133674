interface WaitForOptions {
  interval?: number;
  timeout?: number;
  errorMessage?: string;
}

const waitFor = (
  predicate: () => any,
  { interval = 50, timeout = 20000, errorMessage = '' }: WaitForOptions = {},
): Promise<any> => {
  let intervalId: NodeJS.Timeout;

  return Promise.race([
    timeout && new Promise((_, reject) => setTimeout(() => reject(errorMessage), timeout)),
    new Promise((resolve) => {
      intervalId = setInterval(() => {
        const val = predicate();
        if (val) {
          resolve(val);
        }
      }, interval);
    }),
  ]).finally(() => clearInterval(intervalId!));
};

export default waitFor;
