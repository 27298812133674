import { isBlogSite, setStyle } from '../helpers/utils';

interface BannerSize {
  [index: number]: number;
}

interface ScaleTabletParams {
  name: string;
  size: BannerSize;
}

/**
 * Scaled tablet banner which is greater than viewport or adUnit offsetWidth.
 * @param {ScaleTabletParams} e has adUnitId and banner size
 */
export const scaleTablet = (e: ScaleTabletParams): void => {
  const responsive = 1;
  const bannerWidth = e.size[0];
  const adUnit = document.getElementById(e.name);
  const innerWidth = getInnerWidth();
  const adUnitwidth = innerWidth;
  const scaleFactor = bannerWidth === responsive ? adUnitwidth / 980 : adUnitwidth / bannerWidth;
  const firstChildWidth = adUnitwidth < bannerWidth ? bannerWidth : 980;

  if (adUnitwidth < bannerWidth || bannerWidth === responsive) {
    const adUnitParentHeight =
      bannerWidth === responsive ? 'auto' : (adUnit?.offsetHeight || 0) * scaleFactor + 20 + 'px';

    setStyle(adUnit?.parentElement, { height: adUnitParentHeight });
    setStyle(adUnit, { width: `${adUnitwidth}px` });

    const transformProperties = {
      scale: scaleFactor.toString(),
      'transform-origin': isBlogSite ? 'left top' : 'top',
      width: `${firstChildWidth}px`,
    };
    setStyle(adUnit?.firstElementChild as HTMLElement | null, transformProperties);
  }
};

/**
 * Scaled tablet banner when slotRenderEnded and onresize(viewport change landscape/portrait)
 */
export const scaleAds = (): void => {
  const slotRenderEndedBanner: ScaleTabletParams[] = [];
  window.googletag.cmd.push(() =>
    window.googletag.pubads().addEventListener('slotRenderEnded', function (event: any) {
      const slot = event.slot;
      if (!event.isEmpty) {
        const innerWidth = getInnerWidth();
        const adUnit = document.getElementById(slot.getSlotElementId());
        const index = slotRenderEndedBanner.findIndex((obj) => obj.name === slot.getSlotElementId());
        index === -1 && slotRenderEndedBanner.push({ name: slot.getSlotElementId(), size: event.size });
        if (innerWidth > 480 && innerWidth < 980 && window.innerHeight > 480 && adUnit) {
          scaleTablet({ name: slot.getSlotElementId(), size: event.size });
        }
      }
    }),
  );

  let timeOut: NodeJS.Timeout | null = null;
  window.onresize = () => {
    if (timeOut !== null) clearTimeout(timeOut);
    timeOut = setTimeout(function () {
      const innerWidth = getInnerWidth();
      slotRenderEndedBanner.forEach((e) => {
        const adUnit = document.getElementById(e.name);
        if (innerWidth > 480 && innerWidth < 980 && window.innerHeight > 480 && adUnit) {
          scaleTablet(e);
        } else {
          adUnit?.removeAttribute('style');
          adUnit?.parentElement?.removeAttribute('style');
          adUnit?.firstElementChild?.removeAttribute('style');
        }
      });
    }, 100);
  };
};

/**
 * window.innerWidth alone might not get the actual inner width of viewport,
 * but rather content width with rendered ads in it.
 *
 * @returns integer
 */
const getInnerWidth = (): number => {
  return Math.min(document.documentElement.clientWidth || Number.MAX_VALUE, window.innerWidth || Number.MAX_VALUE);
};
