/**
 * Type definitions for the TCFv2 API (see [1]), which we assume exist prior to
 * this script being run.
 * `window.__tcfapi`
 *
 * - [1] https://github.com/InteractiveAdvertisingBureau/GDPR-Transparency-and-Consent-Framework/blob/master/TCFv2/IAB%20Tech%20Lab%20-%20CMP%20API%20v2.md
 */

type ExtendedWindow = Window & { __tcfapi?: TCFv2API };

type TCFVersion = 2;

type EventHandler = (tcData: TCData, success: boolean) => void;

interface TCData {
  gdprApplies?: boolean;
  eventStatus: Event;
  cmpStatus: CmpStatus;
  listenerId: number;
  tcString: string;
  cmpId: number;
  cmpVersion: number;
}

type AddEventListenerCmd = (command: 'addEventListener', version: TCFVersion, h: EventHandler) => void;
type RemoveEventListenerCmd = (command: 'removeEventListener', version: TCFVersion, listenerId: number) => void;
type PingCmd = (command: 'ping', version: TCFVersion, listenerId: number) => void;
type GetTCDataCmd = (command: 'getTCData', version: TCFVersion, h: EventHandler, vendorIds?: number[]) => void;

type TCFv2API = AddEventListenerCmd & RemoveEventListenerCmd & PingCmd & GetTCDataCmd;

import log from '../helpers/log';

/** @enum {string} Event */
export enum Event {
  Loaded = 'tcloaded',
  CmpShown = 'cmpuishown',
  UserActionComplete = 'useractioncomplete',
}

/** @enum {string} CmpStatus */
export enum CmpStatus {
  Stub = 'stub',
  Loaded = 'loaded',
  Error = 'error',
}

/**
 * Wrapper for the TCFv2 API that ensures we have CMP consent - either by prompting the user, or if
 * consent was already given prior to the current pageview.
 *
 * @return {Promise<{tcData: TCData}>}
 */
const getTcfConsent = async (): Promise<{ tcData: TCData }> => {
  return new Promise((resolve, reject) => {
    window.__tcfapi('addEventListener', 2, (tcData: any, success: any) => {
      log('tcfapi Event with tcData: ', tcData);

      if (tcData) {
        const { eventStatus, gdprApplies, listenerId } = tcData;

        if (!success) {
          window.__tcfapi(
            'removeEventListener',
            2,
            (res: any) => {
              log('removeEventListener called: ', res);
            },
            listenerId,
          );
          reject(new Error(`User rejected consent`));
        }

        if (!gdprApplies) {
          (window).__tcfapi(
            'removeEventListener',
            2,
            (res: any) => {
              log('removeEventListener called: ', res);
            },
            listenerId,
          );
          resolve({ tcData });
        }

        if (eventStatus === Event.Loaded || eventStatus === Event.UserActionComplete) {
          (window).__tcfapi(
            'removeEventListener',
            2,
            (res: any) => {
              log('removeEventListener called: ', res);
            },
            listenerId,
          );
          resolve({ tcData });
        }
      }
    });
  });
};

export default getTcfConsent;
