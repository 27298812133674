const getSessionProp = (key: string) => {
  const val = sessionStorage.getItem(key);
  return val ? JSON.parse(val) : undefined;
};

const setSessionProp = (key: string, value: any) => {
  if (typeof value === 'object' && Object.keys(value).length > 0) {
    try {
      sessionStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.warn(`Couldn not set session storage for `, key, value);
    }
  }
};

const session = {
  get: getSessionProp,
  set: setSessionProp,
};

export default session;
