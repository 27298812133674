type ArrayString = string[] | string

let mergedVerticalParams: ArrayString = [];

const getMergedVerticals = (configVerticals: IVerticals | undefined = undefined) : ArrayString => {
    
    if(mergedVerticalParams.length > 0) {
        return mergedVerticalParams;
    }

    let msVerticals: any = [];
    //@ts-ignore
    if (configVerticals && configVerticals.length > 0) {
        const path = window.location.pathname.split('/');
        let slug = '';
        let match;
    
        if (Array.isArray(path)) {
        // Remove the extra empty end
        path.pop();
    
        // go through all slug parts and look for verticals
        path.map((slugPart) => {
            // Create slug to search for and clean start/end from "/"
            slug = slug + '/' + slugPart;
            slug = slug.replace(/(^\/|\/$)/g, '');
    
            // Find vertical match by domain and slug
            //@ts-ignore
            match = configVerticals.find((site) => {
            return site.domain === window.location.hostname.replace(/(www-staging.|www.)/, '') && site.slug === slug;
            });
            if (match && match.verticals) {
                const filteredVerticals = match.verticals.map( (str: string) => {
                    return str.toLowerCase().startsWith('noje_') ? 'noje' : str;
                });
                msVerticals = [...msVerticals, ...filteredVerticals];
            }
        });
        }
    }
    let gaVerticals = window.aller_ga && window.aller_ga.verticals ? window.aller_ga.verticals : [];
    gaVerticals = typeof gaVerticals === 'string' ? [gaVerticals] : gaVerticals;

    // Merge verticals and remove duplicates
    /* ts-ignore */
    const verticals = [...new Set([...msVerticals, ...gaVerticals])];
    
    if (verticals && verticals.length > 0) {

        mergedVerticalParams = verticals;
        // set all verticals back on window
        window.aller_ga = window.aller_ga || {};
        window.aller_ga.verticals = verticals;
    }    
    return mergedVerticalParams;
}

export const getGamVerticals = (configVerticals: IVerticals | undefined = undefined) => {

    const mergedVerticals = getMergedVerticals(configVerticals);

    if (mergedVerticals && mergedVerticals.length > 0) {
        return mergedVerticals.length == 1 ? mergedVerticals[0] : mergedVerticals;
    }    
}

export const getDmVerticals = () : Array<string> => {

    const mergedVerticals: ArrayString = getMergedVerticals() || [];

    const verticals: Array<string> = Array.isArray(mergedVerticals) ? mergedVerticals : [mergedVerticals];

    const dmVerticals = verticals.map((vertical: string) => {
      return vertical
        .replace(/å/g, 'a')
        .replace(/Å/g, 'a')
        .replace(/ä/g, 'a')
        .replace(/Ä/g, 'A')
        .replace(/ö/g, 'o')
        .replace(/Ö/g, 'O');
    });
    return dmVerticals;
}
