class Network {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl || `${process.env.BASE_URL}`;
  }

  private fetch =
    (preOptions: RequestInit) =>
    async (endpoint: string, options: RequestInit = {}) => {
      const init = { ...preOptions, ...options };
      const resourceUri = endpoint.includes('http') ? endpoint : `${this.baseUrl}${endpoint}`;
      const response = await fetch(resourceUri, init);

      if (!response.ok) {
        throw new Error(`Unable to ${options.method} ${resourceUri}`);
      }

      return response.json();
    };

  public get = this.fetch({ method: 'GET' });
  public post = this.fetch({
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

const network = new Network('');
export default network;
