import adSettings from './adsettings';
import { alterSubdomain } from './domain';
import session from '../storage/session';


const getAdCfgSessionKey = () : string => `ad_cfg_${alterSubdomain(adSettings.hostName)}`;

const getSessionConfig: any = () => {

  const key: string = getAdCfgSessionKey();
  const val = session.get(key);
  return val;
};

const setSessionConfig = (value: any) => {
  const key: string = getAdCfgSessionKey();
  if (typeof value === 'object' && Object.keys(value).length > 0) {
      session.set(key, value);
  }
};

const sessionStorage = {
  get: getSessionConfig,
  set: setSessionConfig,
};

export default sessionStorage;
