import { getSiteName, getHostName } from './domain';
import log from '../helpers/log';

/**
 * Return the final value for a setting from checking if it's available as a global variable
 * @param { Setting } defaultValue
 * @param { string } targetName
 * @return { Setting } Return the resolved setting as a key/value-object
 */
const updateWindowSetting = (defaultValue: Setting, targetName: keyof Window): Setting => {
  const updatedSetting = defaultValue;
  if (typeof window[targetName] !== 'undefined') {
    updatedSetting.value = window[targetName]; 
  }
  return updatedSetting;
};

/**
 * Map default settings against window globals
 * @param { Settings } defaults - a key/value list
 * @return { Settings }
 */
const getUpdateWindowSettings = (defaults: Settings): Settings => {
  const updatedSettings: Settings = {};

  for (const key in defaults) {
    if (defaults.hasOwnProperty(key)) {
      updatedSettings[key] = updateWindowSetting(defaults[key], key as keyof Window);
    }
  }
  return updatedSettings;
};

/**
 * Return and type cast the value for a setting
 *
 * @return { RawSetting }
 * @param { Setting } setting
 */
const getRawSetting = (setting: Setting): RawSetting => {
  const { type = SettingTypes.Default, value } = setting;

  if (type === SettingTypes.Bool) {
    return value === 'false' ? false : Boolean(value);
  }
  return value;
};

/**
 * Flatten each setting to only contain the raw value {string|number}
 * @param { Settings } settings
 * @return { RawSettings }
 */
const getRawSettings = (settings: Settings): RawSettings => {
  const rawSettings: RawSettings = {};

  for (const key in settings) {
    if (settings.hasOwnProperty(key)) {
      rawSettings[key] = getRawSetting(settings[key]);
    }
  }
  return rawSettings;
};

/**
 * Assign raw settings to a target object
 * @param { any } target - The target object to which settings will be assigned
 * @param { RawSettings} settings - The updated raw settings to be assigned
 * @return { void }
 */
const assignSettings = (target: any, settings: RawSettings): void => Object.assign(target, settings);

const getModuleSettings = (): RawSettings  => {

  const moduleSettings: RawSettings = {};

  const siteName = getSiteName();
  if(siteName) {
    moduleSettings['siteName'] = siteName;
  }
  const hostName = getHostName();
  if(hostName) {
    moduleSettings['hostName'] = hostName;
  }
  return moduleSettings;
}

// Enumeration for setting types
const SettingTypes = {
  String: 'string',
  Bool: 'bool',
  Default: 'bool',
};

// Class for managing ad settings
class AdSettings {
  public displayAds: boolean;
  public isNativeAd: boolean;
  public autoloadAds: boolean;
  public device: string;
  public filterAds: boolean;
  public adWatch: boolean;
  public siteName: string = '';
  public hostName: string = '';
  public adsUniqueId: string;

  public defaults: Settings;

  private initDefaults = (): void => {
    log('AdSettings initDefaults');
    if(!this.defaults){
      this.defaults = {
        displayAds: { value: true },
        isNativeAd: { value: false },
        autoloadAds: { value: true, type: SettingTypes.Bool },
        device: { value: window.device, type: SettingTypes.String },
        filterAds: { value: true, type: SettingTypes.Bool },
        adWatch: { value: false },
        siteName: { value: getSiteName(), type: SettingTypes.String },
        hostName: { value: getHostName(), type: SettingTypes.String },
        adsUniqueId: { value: '', type: SettingTypes.String },
      };
      log('AdSettings Defaults UPDATED');
    }
  }

  private build(settings: Settings): void {

    const updatedSettings = getUpdateWindowSettings(settings);
    const rawSettings = getRawSettings(updatedSettings);
    const moduleSettings = getModuleSettings();

    assignSettings(this, { ...rawSettings, ...moduleSettings});
  }
 
  public init = () => {
    this.initDefaults();
    this.refresh();
  };

  public refresh = (): void => {
    this.build(this.defaults);
    log('AdSettings refresh', this);
  };
}

export default new AdSettings();
