import log from '../helpers/log';

const statsCollectorEndpoint = `${process.env.STATS_COLLECTOR}`;
const statsCollectorAdsEndpoint = `${process.env.ADS_STATS_COLLECTOR}`;

interface Stats {
  clientTsInMs: number;
  //device: any;
  site: string;
  stats: any[];
  //userAgent: string;
}

interface SiteMapping {
  [key: string]: string;
}

const siteMapping: SiteMapping = {
  residencemagazine: 'residence',
};

const getSiteFromDomain = (site: string): string => siteMapping[site] ?? site;

export function AdStats(site: string, device: any) {
  let _device = device;
  let _site = getSiteFromDomain(site);
  let _stats: any[] = [];

  const add = (stats: any[]): any[] => {
    if (Array.isArray(stats) && stats.length) {
      _stats = [..._stats, ...stats];
    }
    return _stats;
  };

  const payload = (): Stats => ({
    clientTsInMs: Date.now(),
    //device: _device,
    site: _site,
    stats: [..._stats],
    //userAgent: navigator.userAgent
  });

  const toJSON = (): string | undefined => {
    try {
      const response = JSON.stringify(payload());
      return response;
    } catch (e) {}
  };

  const appendFromJSON = (payload: string): void => {
    const parsed = JSON.parse(payload);
    _stats = [..._stats, ...parsed.stats];
  };

  const push = (): void => {
    if (_stats.length) {
      // TODO: Need to improve this bit to make sure we don't get corrupt data
      const body = toJSON();
      if (body) {
        _stats = [];
        fetch(statsCollectorEndpoint, {
          method: 'POST',
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          body,
        }).catch(() => {
          log(`AdStats::push - Unable to push stats, re-adding.`);
          appendFromJSON(body);
        });
        // GCP ENDPOINT
        fetch(statsCollectorAdsEndpoint, {
          method: 'POST',
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          body,
        }).catch(() => {
          log(`AdStats::push - Unable to push stats to GCP, re-adding.`);
          appendFromJSON(body);
        });
      } else {
        log(`AdStats::push - unable to retrieve retrieve payload..`);
      }
    } else {
      log(`AdStats::push - no stats available for sending to API.`);
    }
  };

  return {
    all: (): any[] => _stats,
    add,
    push,
    toJSON,
    payload,
  };
}
